<template>
  <b-img
    v-if="agency"
    :src="appLogoImage"
    alt="logo"
    style="width: 150px;"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'
// import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  computed: {
    agency() {
      return this.$store.state.app.agency
    },
    appLogoImage() {
      return this.$store.state.app.agency?.logo.file_url
    },
  },
}
</script>
